import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import * as moment from 'dayjs'
import { FacadeService, LoggerFactory, GeoType, KioskService } from 'src/app/services/public-api'
import { find, get } from 'lodash'
import { Customer, DestTypes, Store } from '@golo/models/lib/interfaces'
import { GestureController, LoadingController } from '@ionic/angular'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { SeqModalControllerService } from 'src/app/services/seq-modal-controller.service'
import { Router } from '@angular/router'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Component({
	selector: 'app-optiondetails',
	templateUrl: './optiondetails.component.html',
	styleUrls: ['./optiondetails.component.scss'],
})
export class OptiondetailsComponent implements OnInit {
	@ViewChild('options', { read: ElementRef, static: true })
	options: ElementRef

	orderMethod = ''
	orderMethodDescription = ''
	orderTime = ''
	orderDestination = ''
	selectedStore: Store
	orderText = ''
	storeAlive: boolean
	prettyTimeSeparator = ',' // use this for the friendly english wording
	selectedStoreId
	geoRef = 'geo:'
	statusMessage = 'Choose a Store'

	@Input() forceDetails: boolean
	@Input() isRewards: boolean

	addressState: 'PRECISE' | 'HASPRECISE' | 'NOPRECISE'

	constructor(
		private modal: SeqModalControllerService,
		public services: FacadeService,
		private profile: CustomerProfileService,
		public gestureCtrl: GestureController,
		private router: Router,
		private kioskService: KioskService
	) {}

	async ngOnInit() {
		combineLatest([this.profile.watch(), this.profile.watchCurrentStore()])
			.pipe(
				map((tup: [Customer, Store | null]) => {
					const [customer, store] = tup
					if (store) {
						customer.currentSelection.store = store
					}
					return customer
				})
			)
			.subscribe(async (customer: Customer) => {
				this.orderTime =
					customer.currentSelection.when === 'schedule'
						? this.prettyTime(get(customer, 'currentSelection.scheduleDateTime'))
						: 'ASAP'

				this.tableOrderMethod(customer)
				this.updateOrderMethod(customer)
				this.orderDestination = get(customer, 'currentSelection.location.nickName')
				this.setAddressState(customer)

				if (customer.currentSelection.store) {
					this.selectedStoreId = customer.currentSelection.store._id
					this.selectedStore = customer.currentSelection.store
					this.storeAlive = customer.currentSelection.store.status?.isAlive
					this.setOrderText(customer)
					this.getStoreStatus()
					if (customer.currentSelection.orderingMethod) {
						this.services.cart.updatePrices(customer.currentSelection.orderingMethod.pricingOption)
					}
				}
			})

		//FIXME causes infinite loop
		if (this.selectedStore) {
			this.refreshTrading()
		}

		if (this.forceDetails) {
			this.openOptions()
		}
	}

	updateOrderMethod(customer: Customer) {
		const storeOrderMethods = get(customer, 'currentSelection.store.orderingMethods')
		const customerOrderMethod = this.services.cart.translateDestination(customer.currentSelection.destinationType)
		console.log('order methods', storeOrderMethods)
		console.log('customer methods', customerOrderMethod)
		if (
			!storeOrderMethods.some(
				(orderMethods) =>
					this.services.cart.translateDestination(orderMethods.name.toLowerCase().replace(' ', '')) ==
					customerOrderMethod
			)
		) {
			customer.currentSelection.destinationType = null
		}

		if (!customer.currentSelection.destinationType) {
			const defaultOrderMethod = storeOrderMethods[0]
			switch (defaultOrderMethod?.name) {
				case 'Collection':
					customer.currentSelection.destinationType = DestTypes.COLLECT
					this.orderMethodDescription = defaultOrderMethod.salesOrderTag.name
					break
				case 'Delivery':
					customer.currentSelection.destinationType = DestTypes.DELIVERY
					this.orderMethodDescription = defaultOrderMethod.salesOrderTag.name
					break
				case 'Curbside':
					customer.currentSelection.destinationType = DestTypes.CURBSIDE
					this.orderMethodDescription = defaultOrderMethod.salesOrderTag.name
					break
				case 'Eat In':
					customer.currentSelection.destinationType = DestTypes.EATIN
					this.orderMethodDescription = defaultOrderMethod.salesOrderTag.name
					break
				case 'Drive Thru':
					customer.currentSelection.destinationType = DestTypes.DRIVETHRU
					this.orderMethodDescription = defaultOrderMethod.salesOrderTag.name
					break
			}
			this.orderMethod = customer.currentSelection.destinationType
		} else {
			this.orderMethod = customer.currentSelection.destinationType
			this.orderMethodDescription = customer.currentSelection.store.orderingMethods.find(
				(item) =>
					this.services.cart.translateDestination(item.name.toLowerCase().replace(' ', '')) ===
					this.services.cart.translateDestination(customer.currentSelection.destinationType)
			)?.salesOrderTag.name
			console.log(this.orderMethodDescription)
		}
	}

	setOrderText(customer: Customer) {
		switch (customer.currentSelection.destinationType) {
			case 'delivery':
				this.orderText = 'Delivering <a class="lighter">to</a> ' + this.orderDestination
				break
			case 'curbside':
				this.orderText = 'Receiving <a class="lighter">at the</a> Curbside'
				break
			case 'collect':
				this.orderText = 'Collecting <a class="lighter">in the</a> Store'
				break
			case 'eatin':
				this.orderText = 'Eat In <a class="lighter">in the</a> Store'
				break
		}
		this.orderText = `${this.orderText} ${this.orderTime}`
	}

	private setAddressState(customer: Customer) {
		switch (true) {
			case this.orderDestination && get(customer, 'currentSelection.location.meta.type') === GeoType.PRECISE:
				this.addressState = 'PRECISE'
				break
			case !!find(customer.locations, ['meta.type', GeoType.PRECISE]):
				this.addressState = 'HASPRECISE'
				break
			default:
				this.addressState = 'NOPRECISE'
				break
		}
	}

	tableOrderMethod(customer: Customer) {
		let orderMethodName = ''
		const tableNo = this.kioskService.getKioskTableNo()

		let isEatEnabled = customer.currentSelection.store.orderingMethods.some(({ name }) => name === 'Eat In')

		if (!isEatEnabled && tableNo) {
			let orderMethodStatus = 'Order at Table is not available'
			this.services.toast.toast({ text: orderMethodStatus })
			this.statusMessage = orderMethodStatus
			return
		}

		if (tableNo) {
			orderMethodName = customer.currentSelection.store.orderingMethods.find((x) => x.name === 'Eat In').name
			customer.currentSelection.destinationType = DestTypes.EATIN
		}

		return orderMethodName
	}

	async ngAfterViewInit() {
		let gesture = await this.gestureCtrl.create({
			el: this.options.nativeElement,
			gestureName: 'header-swipe',
			gesturePriority: 10,
			threshold: 5,
			direction: 'y',
			passive: false,
		})
		gesture.enable(true)
	}

	openOptions() {
		if (window.location.pathname.includes('orderstatus')) {
			return
		}
		const kioskTableNo = this.kioskService.getKioskTableNo()
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const tableUrlParams = urlParams.get('table')
		if (kioskTableNo && tableUrlParams) {
			let orderMethodStatus = ' The option to change the store is unavailable'
			this.services.toast.toast({ text: orderMethodStatus })
			return
		}
		const navigateToRewards = () => {
			this.router.navigate(['/home'], { queryParams: { skipLand: true, returnTo: '/rewards' } })
		}
		const openOptionsModal = () => {
			localStorage.removeItem('table_params')
			this.modal.openOptionsModalSeq()
		}
		this.isRewards ? navigateToRewards() : this.selectedStore && openOptionsModal()
	}

	prettyTime(t: string) {
		let m = moment(t)
		switch (m.diff(moment().startOf('day'), 'day')) {
			case 0:
				return m.format('HH:mm') + ', Today'
			case 1:
				return m.format('HH:mm') + ', Tomorrow'
			default:
				return m.format('HH:mm, dddd DD/MM')
		}
	}

	async getStoreStatus() {
		if (this.selectedStoreId) {
			this.services.api
				.storeStatus(this.selectedStoreId)
				.pipe(
					map((resp: any) => {
						this.storeAlive = resp.ISALIVE
					})
				)
				.subscribe()
		}
	}

	refreshTrading() {
		const storeOrderingMethod = get(this.profile, 'customer.currentSelection.store.orderingMethods', []).find(
			(oo) => oo.name === get(this.profile, 'customer.currentSelection.orderingMethod.name')
		)
		const dateToCheck =
			get(this.profile, 'customer.currentSelection.when') === 'asap'
				? new Date()
				: get(this.profile, 'customer.currentSelection.scheduleDateTime')
		if (get(this.profile, 'customer.currentSelection.when') === 'asap') {
			this.profile.updateCurrentStoreStatus({
				isOpen: this.services.trading.isTrading(storeOrderingMethod?.availability, dateToCheck),
			})
		}
	}

	openLink(ev: Event) {
		window.open(
			'https://www.google.com/maps/dir/?api=1&destination=' + this.selectedStore?.coords,
			'_system',
			'location=yes'
		)
		ev.stopPropagation()
	}

	home() {
		this.router.navigate(['/home'], { queryParams: { skipLand: true } })
	}
}
